import React, { useEffect, useState } from 'react';
import { Popover, Splitter, List } from 'antd';
import { getFromLocalStorage, CONSTANT } from '../../Utils';

const ReturnList = (ans) => (
    <List size="small" style={{ padding: 10 }}>
        <div><b>{ans?.concept.name?.replace(/[A-Z-_\&](?=[a-z0-9]+)|[A-Z-_\&]+(?![a-z0-9])/g, ' $&')?.trim()}</b></div>
        <div>{ans.person.first_name} {ans.person.last_name}</div>
        <div>{new Date(ans.created_at).toLocaleString('es-PY', { year: 'numeric', month: '2-digit', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</div>
    </List>
)

const Desc = ({ title, data }) => (
    <div>
        <div style={{ fontSize: 16, fontWeight: 600, padding: 10 }}>{title}</div>
        <div style={{ overflow: 'auto', height: 250 }}>
            {title.toLowerCase() === 'answered' ? data.allAnswerd.map(ans =>
                ReturnList(ans)
            ) : data.allCorrection.map(ans =>
                ReturnList(ans)
            )}
        </div>
    </div>
);


export const PopoverComponent = ({ show, data, children, onClick }) => {
    const [userInfo, setUserInfo] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getUser();
        setOpen(show)
        if (show) {
            processData();
        }

    }, [show])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    const processData = () => {
        return (
            <Splitter style={{ height: 300, width: 500, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                {[userInfo?.user?.person_type_id === CONSTANT.REVIEWER ? 'Answered' : 'Need Correction',
                userInfo?.user?.person_type_id !== CONSTANT.REVIEWER ? 'Answered' : 'Need Correction'].map(title =>
                    <Splitter.Panel style={{ width: '100%', flexBasis: 250 }}>
                        <Desc title={title} data={data} />
                    </Splitter.Panel>
                )}
            </Splitter>
        )
    }

    const handleOpenChange = () => {
        onClick('openBadge', !show)
    };

    return (
        <>
            <Popover content={processData} trigger="click" open={open} onOpenChange={handleOpenChange}>
                {children}
            </Popover>
        </>
    );
};