import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { PatientInformation } from './PatientInformation';
import { MedicalHistory } from './MedicalHistory';
import { InfectionHistory } from './InfectionHistory';
import { Diagnosis } from './Diagnosis';
import { EMR } from './EMR';
import { OnExamination } from './OnExamination';
import { SlitLampFinding } from './SlitLampFinding';
import { FollowUp } from './FollowUp';
import { Button } from 'antd';
import { CONSTANT, Server, getFromLocalStorage } from '../../../Utils';
import { SlitLampFindingPhoto } from './SiltLampFindingPhoto';
import { SubmitWrapper, ChatWrapper } from './style';
import { Services } from '../../../__services';
import { ModalComponent } from '../../../Component/Common';
import { AuditBot } from '../../../Component/AuditBot';
import { WechatOutlined } from '@ant-design/icons';
import { AuditFunction } from './Audit/AuditFunction';
import { Context } from "../../ContextProvider";

let fixConcepts = [
    { name: 'Visual Acuity Right', value: CONSTANT.VARIGHT }, { name: 'Visual Acuity Left', value: CONSTANT.VALEFT }, { name: 'Pinhole Right', value: CONSTANT.PINEHOLERIGHT },
    { name: 'Pinhole Left', value: CONSTANT.PINEHOLELEFT }, { name: 'VA mesurement right', value: CONSTANT.EMRVAMEASUREMENTRIGHT }, { name: 'VA mesurement left', value: CONSTANT.EMRVAMEASUREMENTLEFT },
    { name: 'LacrimalDuct Right', value: CONSTANT.EMRLACRIMALDUCTRIGHT }, { name: 'LacrimalDuct Left', value: CONSTANT.EMRLACRIMALDUCTLEFT }, { name: 'IOP Right', value: CONSTANT.EMRIOPRIGHT },
    { name: 'IOP left', value: CONSTANT.EMRIOPLEFT }, { name: 'Right Eye lens', value: CONSTANT.DIAGNOSISRIGHTEYELENS }, { name: 'Left Eye lens', value: CONSTANT.DIAGNOSISLEFTEYELENS },
    { name: 'Right Eye Additional Pathalogy', value: CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY }, { name: 'Left Eye Additional Pathalogy', value: CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY },
    { name: 'Right Eye Corneal', value: CONSTANT.DIAGNOSISRIGHTEYECORNEAL }, { name: 'Left Eye Corneal', value: CONSTANT.DIAGNOSISLEFTEYECORNEAL }, { name: 'Corneal Infection', value: CONSTANT.DIAGNOSISCOREALINFECTION },
    { name: 'Follow Up', value: CONSTANT.INFECTIONHISTORYRIGHTFOLLOWUP }
]

let cornealConceptsRight = [
    { name: 'Epithelial - Right', value: CONSTANT.INFECTIONHISTORYRIGHTEPITHELIAL }, { name: 'Working Diagnosis - Right', value: CONSTANT.INFECTIONHISTORYRIGHTWORKING },
    { name: 'Depth - Right', value: CONSTANT.INFECTIONHISTORYRIGHTDEPTH },
    { name: 'Specific exam - Right', value: CONSTANT.INFECTIONHISTORYRIGHTSPECIFIC }, { name: 'Hypopyon - Right', value: CONSTANT.INFECTIONHISTORYRIGHTHYPOPYON },
    { name: 'Microbiology - Right', value: CONSTANT.INFECTIONHISTORYRIGHTMICROBIOLOGY }, { name: 'Centerally - Right', value: CONSTANT.INFECTIONHISTORYRIGHTCENTERALLY },
    { name: 'Diameter - Right', value: CONSTANT.INFECTIONHISTORYRIGHTDIAMETER }, { name: 'LIMBUS - Right', value: CONSTANT.INFECTIONHISTORYRIGHTLIMBUS },
    { name: 'Procedure - Right', value: CONSTANT.INFECTIONHISTORYRIGHTPROCEDURE },
    { name: 'Medical History - Right', value: CONSTANT.MEDICALHISTORYRIGHT },
]

let notImgRight = [
    { name: 'Stromal - Right', value: CONSTANT.INFECTIONHISTORYRIGHTSTROMAL },
]

let cornealConceptsLeft = [
    { name: 'Epithelial - Left', value: CONSTANT.INFECTIONHISTORYLEFTEPITHELIAL }, { name: 'Working Diagnosis - Left', value: CONSTANT.INFECTIONHISTORYLEFTWORKING },
    { name: 'Depth - Left', value: CONSTANT.INFECTIONHISTORYLEFTDEPTH },
    { name: 'Specific exam - Left', value: CONSTANT.INFECTIONHISTORYLEFTSPECIFIC }, { name: 'Hypopyon - Left', value: CONSTANT.INFECTIONHISTORYLEFTHYPOPYON },
    { name: 'Microbiology - Left', value: CONSTANT.INFECTIONHISTORYLEFTMICROBIOLOGY }, { name: 'Centerally  - Left', value: CONSTANT.INFECTIONHISTORYLEFTCENTERALLY },
    { name: 'Diameter - Left', value: CONSTANT.INFECTIONHISTORYLEFTDIAMETER }, { name: 'LIMBUS - Left', value: CONSTANT.INFECTIONHISTORYLEFTLIMBUS },
    { name: 'Procedure - Left', value: CONSTANT.INFECTIONHISTORYLEFTPROCEDURE },
    { name: 'Medical History - Left', value: CONSTANT.MEDICALHISTORYLEFT },
]

let notImgLeft = [
    { name: 'Stromal - Left', value: CONSTANT.INFECTIONHISTORYLEFTSTROMAL },
]

let therapeuticConceptRight = [{ name: 'Therapeutic - Right', value: CONSTANT.INFECTIONHISTORYRIGHTTHERAPEUTIC }]
let therapeuticConceptLeft = [{ name: 'Therapeutic - Left', value: CONSTANT.INFECTIONHISTORYLEFTTHERAPEUTIC }]

let nestedConceptsRight = [
    { name: 'Presentation Days - Right', value: CONSTANT.INFECTIONHISTORYRIGHTPRESENTATIONDATE },
    { name: 'Symptoms Days - Right', value: CONSTANT.INFECTIONHISTORYRIGHTSYMPTOMSDAYS },
    { name: 'Risk factor - Right', value: CONSTANT.INFECTIONHISTORYRIGHTRISKFACTOR }
]

let nestedConceptsLeft = [
    { name: 'Presentation Days - Left', value: CONSTANT.INFECTIONHISTORYLEFTPRESENTATIONDATE },
    { name: 'Symptoms Days - Left', value: CONSTANT.INFECTIONHISTORYLEFTSYMPTOMSDAYS },
    { name: 'Risk factor - Left', value: CONSTANT.INFECTIONHISTORYLEFTRISKFACTOR }
]

let scehConcepts = [
    { name: 'Blood Sugar', value: CONSTANT.EMRBLOODSUGAR }, { name: 'Referral', value: CONSTANT.EMRREFERRAL }
]

export const UserVisitSummary = ({ adultinitial, visitInProgress, visitComplete, visitReview, visitQuery, showInfectionFragmentRight, showInfectionFragmentLeft, showMedicationFragmentRight, showMedicationFragmentLeft, showFirstVisitInfection, onChange, onClick }) => {
    const { updateState } = useContext(Context);
    const [userInfo, setUserInfo] = useState();
    const [showModal, setShowModal] = useState(false);
    const [encounterType, setEncounterType] = useState();
    const [notCompeleteConcepts, setNotCompleteConcepts] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [allAudit, setAllAudit] = useState();
    const [allFilterAudit, setAllFilterAudit] = useState([]);
    const [isAudit, setIsAudit] = useState(false);
    const [auditTitle, setAuditTitle] = useState('');
    const [auditConcept, setAuditConcept] = useState(false);
    const [auditValue, setAuditValue] = useState({ status: undefined, value: undefined });
    const [auditId, setAuditId] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getUser();
            getAllAudits();
            isFirstRender.current = false

            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit, allAudit])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    const onClickHandler = (type, value) => {
        if (['save', 'visitReview', 'visitQuery', 'visitComplete'].includes(type)) {
            setSubmit(true);
            setEncounterType(type);
            setTimeout(() => {
                getVisitAuditAndProcess(type);
                setSubmit(false);
            }, 2000);
        } else if (type === 'value') {
            setIsAudit(prevCheck => !prevCheck);
        } else if (type === 'submitComment') {
            if (auditId) {
                editAudit();
            } else {
                saveAudit();
            }
        } else if (type === 'auditEdit') {
            setAuditValue(value.audit);
            setAuditId(value.audit.id)
        } else if (type === 'auditDelete') {
            deleteAudit(value.id, 'audit');
        } else if (type === 'close-bot') {
            setIsAudit(false);
            setAuditValue({ status: undefined, value: undefined });
        }
    }

    const onChangeHandler = async (type, value, extraData) => {
        if (type === 'auditValue') {
            if (extraData === 'status') {
                setAuditValue({ ...auditValue, status: value });
            } else {
                setAuditValue({ ...auditValue, value: value });
            }
        } else if (['EMRAudit', 'diagnosisAudit', 'infectionHistoryAudit', 'followUpAudit', 'medicalHistoryAudit'].includes(type)) {
            filterAudit(type, value)
            setAuditConcept(value);
            setAuditTitle(extraData)
        } else if (type === 'fetchAudit') {
            getAllAudits();
        } else {
            onChange(type, value, extraData);
        }
    }

    const getAllAudits = async () => {
        try {
            if (visitInProgress) {
                let data = await AuditFunction.getAllAudits(visitInProgress);
                setAllAudit(data);
                updateState('badge', data);
                // let queries = document.querySelector('#audit-badge > sup > span > span');
                // if (queries) {
                //     queries.innerHTML = AuditFunction?.allAudit?.allUnique?.totalCount;
                // }
            }
        } catch (err) {
            console.log('get audit exception');
        }
    }

    const filterAudit = async (type, concept) => {
        try {
            let data = await AuditFunction.filterAudit(type, concept);
            setIsAudit(true);
            setAllFilterAudit(data);
        } catch (err) {
            console.log('filter exception: ', err)
        }
    }

    const saveAudit = async () => {
        try {
            let { id, patient_id, visit_id } = visitInProgress;
            let payload = {
                encounter_id: id,
                creator_id: userInfo.user.person_id,
                concept_id: auditConcept,
                patient_id,
                visit_id,
                status: auditValue.status,
                value: auditValue.value
            }
            Services.PostObsAudit(payload).then((response) => {
                if (response.status !== 400) {
                    setAllFilterAudit(prev => [...prev,
                    {
                        id: response?.id,
                        audit: {
                            id: response?.id,
                            status: response?.status,
                            value: response?.value,
                        },
                        time: new Date().toLocaleString('es-PY', { year: 'numeric', month: '2-digit', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }),
                        name: `${userInfo?.user?.person?.first_name} ${userInfo?.user?.person?.last_name}`
                    }
                    ]);
                }
                resetValues();
                getAllAudits();
            })
        } catch (e) {
            console.log('save comment exception: ', e);
        }
    }

    const editAudit = async () => {
        try {
            let { id, patient_id, visit_id } = visitInProgress;
            let payload = {
                id: auditId,
                encounter_id: id,
                creator_id: userInfo.user.person_id,
                concept_id: auditConcept,
                patient_id,
                visit_id,
                status: auditValue.status,
                value: auditValue.value
            }
            Services.PutObsAudit(auditId, payload).then(response => {
                if (response?.modified_by) {
                    resetValues();
                    let index = allFilterAudit.findIndex(ac => ac.audit.id === response.id);
                    if (index !== -1) {
                        allFilterAudit[index].audit = {
                            ...allFilterAudit[index].audit,
                            value: response.value,
                            status: response.status
                        };
                    }
                }
            })
        } catch (e) {
            console.log('Edit comment exception: ', e);
        }
    }

    const deleteAudit = (id, path) => {
        try {
            Services.DeleteObsAudit(id).then(response => {
                if (response.status !== 400 && response.affected) {
                    if (path === 'audit') {
                        let newData = [...allFilterAudit];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setAllFilterAudit(newData);
                        }
                    }
                }
            })
        } catch (e) {
            console.log('Delete Audit Exception: ', e);
        }

    }

    const getVisitAuditAndProcess = async (encounterType = '') => {
        let data;
        if (![CONSTANT.REVIEWER].includes(userInfo?.user?.person_type_id)) {
            data = await checkVisitData();
        }
        if (data.length) {
            setNotCompleteConcepts(data);
            setShowModal(true);
            return;
        }
        if (encounterType === 'save') {
            navigate('/home', { state: location.state });
        } else if (encounterType === 'visitReview') {
            onChange('postVisitReview');
        } else if (encounterType === 'visitQuery') {
            onChange('postVisitQuery');
        } else if (encounterType === 'visitComplete') {
            onChange('visitComplete-diagnosis');
        }
    }

    const checkVisitData = () => {
        try {
            return new Promise((resolve) => {
                let { id, patient_id, visit_id } = visitInProgress;
                let params = `encounter_id=${id}&patient_id=${patient_id}&visit_id=${visit_id}`;
                Services.GetObsWithParams(params).then(response => {
                    if (response.rows.length) {
                        let data = [];
                        let concepts = Server.ENV.name === 'sceh' ? [...fixConcepts, ...scehConcepts] : fixConcepts;
                        if (showInfectionFragmentRight) {
                            concepts = [...concepts, ...cornealConceptsRight];
                            let procedureValue = response.rows.filter(visit => [CONSTANT.INFECTIONHISTORYRIGHTPROCEDURE].includes(visit.concept_id));
                            if (procedureValue.length) {
                                try {
                                    let values = procedureValue[0]?.obs_string?.value?.split(',');
                                    let therapeutic = values?.find(ft => ft.match('Therapeutic Keratoplasty Decided'));
                                    if (therapeutic) {
                                        if (therapeutic.match('Yes')) {
                                            concepts = [...concepts, ...therapeuticConceptRight];
                                        }
                                    }
                                } catch (e) {
                                    console.log('therapeutic exception', e)
                                }
                            }
                        }
                        if (showInfectionFragmentLeft) {
                            concepts = [...concepts, ...cornealConceptsLeft];
                            let procedureValue = response.rows.filter(visit => [CONSTANT.INFECTIONHISTORYLEFTPROCEDURE].includes(visit.concept_id));
                            if (procedureValue.length) {
                                try {
                                    let values = procedureValue[0]?.obs_string?.value?.split(',');
                                    let therapeutic = values?.find(ft => ft.match('Therapeutic Keratoplasty Decided'));
                                    if (therapeutic) {
                                        if (therapeutic.match('Yes')) {
                                            concepts = [...concepts, ...therapeuticConceptLeft];
                                        }
                                    }
                                } catch (e) {
                                    console.log('therapeutic exception', e)
                                }
                            }
                        }
                        if (showFirstVisitInfection) {
                            if (showInfectionFragmentRight) {
                                concepts = [...concepts, ...nestedConceptsRight];
                            }
                            if (showInfectionFragmentLeft) {
                                concepts = [...concepts, ...nestedConceptsLeft];
                            }
                        }
                        if (Server.ENV.name !== 'img') {
                            if (showInfectionFragmentRight) {
                                concepts = [...concepts, ...notImgRight];
                            }
                            if (showInfectionFragmentLeft) {
                                concepts = [...concepts, ...notImgLeft];
                            }
                        }
                        concepts.forEach(concept => {
                            if (!response.rows.find(obs => concept.value === obs.concept_id)) {
                                data.push(concept.name);
                            }
                        })
                        
                        resolve(data);
                        // else {
                        //     console.log(encounterType)
                        //     if (encounterType === 'save') {
                        //         navigate('/home', { state: location.state });
                        //     } else if (encounterType === 'visitReview') {
                        //         onChange('postVisitReview');
                        //     } else if (encounterType === 'visitQuery') {
                        //         onChange('postVisitQuery');
                        //     } else if (encounterType === 'visitComplete') {
                        //         onChange('visitComplete-diagnosis');
                        //     }
                        // }
                    } else {
                        setNotCompleteConcepts(['No observation for this visit']);
                        resolve([]);
                    }
                })
            });
        } catch (e) {
            console.log('Audit Exception: ', e);
        }
    }

    const resetValues = () => {
        setAuditValue({ status: undefined, value: undefined });
        setAuditId();
    }


    return (
        <>
            <div style={{ fontSize: 30, textAlign: 'center', paddingBottom: 10 }}>Ophthalmologist Diagnosis</div>
            <h2 style={{ textAlign: 'center', paddingBottom: 10 }}>{new Date(adultinitial?.created_at)?.toLocaleDateString('en-US')}</h2>
            <PatientInformation data={adultinitial.patient} disabled />
            <br />
            <OnExamination data={adultinitial} />
            <br />
            <EMR style={true ? { pointerEvents: "none", opacity: "0.4" } : {}} data={visitInProgress} visitComplete={visitComplete} submit={submit} showInfectionFragmentRight={showInfectionFragmentRight} showInfectionFragmentLeft={showInfectionFragmentLeft} audit={allAudit} onChange={onChangeHandler} />
            <br />
            <Diagnosis data={visitInProgress} visitComplete={visitComplete} onChange={onChangeHandler} submit={submit} audit={allAudit} />
            {showInfectionFragmentRight || showInfectionFragmentLeft ?
                <>
                    <br />
                    <div style={{ display: showInfectionFragmentRight && showInfectionFragmentLeft ? 'flex' : '', gap: '1%' }}>
                        {showInfectionFragmentRight ? <InfectionHistory name="Right" data={visitInProgress} visitComplete={visitComplete} submit={submit} onChange={onChangeHandler} showFirstVisitInfection={showFirstVisitInfection} showInfectionFragmentRight={showInfectionFragmentRight} audit={allAudit} /> : null}
                        {showInfectionFragmentLeft ? <InfectionHistory name="Left" data={visitInProgress} visitComplete={visitComplete} submit={submit} onChange={onChangeHandler} showFirstVisitInfection={showFirstVisitInfection} showInfectionFragmentLeft={showInfectionFragmentLeft} audit={allAudit} /> : null}
                    </div>

                </>
                : null}

            {/* <br /> */}
            {/* <SlitLampFinding data={visitInProgress} /> */}

            {/* {showMedicationFragmentRight || showMedicationFragmentLeft ?
                <>
                    <br />
                    <div style={{ display: showMedicationFragmentRight && showMedicationFragmentLeft ? 'flex' : '', gap: '1%' }}>
                        {showMedicationFragmentRight ? <MedicalHistory name="Right" data={visitInProgress} /> : null}
                        {showMedicationFragmentLeft ? <MedicalHistory name="Left" data={visitInProgress} /> : null}
                    </div>
                </>
                : null} */}
            <br />
            <FollowUp data={visitInProgress} visitComplete={visitComplete} onChange={onChangeHandler} audit={allAudit} />
            <br />
            <div style={{ display: 'flex', gap: '1%' }}>
                <MedicalHistory name="Right" data={visitInProgress} visitComplete={visitComplete} onChange={onChangeHandler} audit={allAudit} />
                <MedicalHistory name="Left" data={visitInProgress} visitComplete={visitComplete} onChange={onChangeHandler} audit={allAudit} />
            </div>
            <br />
            {Server.ENV.name === 'sceh' ? <SlitLampFindingPhoto data={visitInProgress} /> : null}
            <br />

            <SubmitWrapper>
                <Button type="primary" onClick={() => onClickHandler('save')}>Save and exit</Button> &nbsp;
                {![CONSTANT.REVIEWER].includes(userInfo?.user?.person_type_id) ? <><Button type="primary" onClick={() => onClickHandler('visitReview')}>Complete Visit</Button>&nbsp;</> : null}
                {[CONSTANT.REVIEWER].includes(userInfo?.user?.person_type_id) ? <><Button type="primary" onClick={() => onClickHandler('visitQuery')}>Send For Updates</Button> &nbsp;</> : null}
                {[CONSTANT.REVIEWER].includes(userInfo?.user?.person_type_id) ? <><Button type="primary" onClick={() => onClickHandler('visitComplete')} style={{ background: 'green' }}>Review Complete. Lock Visit</Button></> : null}
            </SubmitWrapper>

            <>
                {/* <ChatWrapper>
                    <WechatOutlined style={{ color: '#008000', fontSize: '40px' }} onClick={() => onClickHandler('comment')} />
                </ChatWrapper> */}
                {isAudit ? <AuditBot title={auditTitle} allAudit={allFilterAudit} auditValue={auditValue} onChange={onChangeHandler} onClick={onClickHandler} /> : null}
            </>

            {showModal ?
                <ModalComponent
                    width='450px'
                    open={true}
                    title={encounterType === 'save' ? 'Are you sure you want to exit?' : 'Are you sure you want to complete this visit?'}
                    saveData={() => encounterType === 'save' ? navigate('/home', { state: location.state }) : onClick('visitComplete-diagnosis')}
                    onCancel={() => { setShowModal(false) }}>
                    <h4>The following fields are not complete: </h4>
                    <ol>{notCompeleteConcepts.map(name => <li key={name}>{name}</li>)}</ol>
                </ModalComponent> : null}
        </>
    )
}