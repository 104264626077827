import { Alert, Card, Radio, notification, Space, DatePicker } from "antd";
import { DeleteFilled, QuestionCircleFilled } from '@ant-design/icons'
import { useRef, useEffect, useState } from "react";
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage, getColor } from "../../../../Utils";
import dayjs from 'dayjs';
import { FollowUpInline } from "./style";
import { AuditFunction } from "../Audit/AuditFunction";

const getConcept = (side, name) => {
    if (name === 'followUp') {
        return CONSTANT[side === 'Right' ? 'INFECTIONHISTORYRIGHTFOLLOWUP' : 'INFECTIONHISTORYLEFTFOLLOWUP'];
    }
}

export const FollowUp = ({ data, visitComplete, name = 'Right', onChange, audit }) => {
    const [userInfo, setUserInfo] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [followUpDiagnosis, setFollowUpDiagnosis] = useState([]);

    const [followUp, setFollowUp] = useState('');
    const [followUpValue, setFollowUpValue] = useState('');
    const [followUpAudit, setFollowUpAudit] = useState([]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getFollowUp();
            getUser();
            isFirstRender.current = false
            return;
        }

        if (audit) {
            setFollowUpAudit(audit.followUpAudit);
        }

        if (['No', 'As needed'].includes(followUp) || followUpValue) {
            submitFollowUp();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [followUp, followUpValue, audit])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteFollowUp') {
            deleteFollowUp(value, path);
        } else if (type === 'audit') {
            onChange('followUpAudit', value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'followUp') {
            setFollowUp(value);
        } else if (type === 'followUpValue') {
            setFollowUpValue(value);
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getFollowUp = async () => {
        setFollowUpDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTFOLLOWUP' : 'INFECTIONHISTORYLEFTFOLLOWUP']) || []);
    }

    const submitFollowUp = async () => {
        try {
            let allData = [], editData = [];
            let payload = {
                encounter_id: data.id,
                creator_id: userInfo.user.person_id,
                patient_id: data.patient_id,
                visit_id: data.visit_id,
            }
            if (followUp.length) {
                if (followUpDiagnosis.length) {
                    let id = followUpDiagnosis[0]?.obs_string?.id
                    let putPayload = {
                        obs_id: followUpDiagnosis[0]?.id,
                        value: followUp !== 'Yes' ? followUp : followUpValue
                    }
                    let auditPayload = {
                        ...payload,
                        concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTFOLLOWUP' : 'INFECTIONHISTORYLEFTFOLLOWUP'],
                        status: 'Answered',
                        value: followUpDiagnosis[0]?.obs_string?.value
                    }
                    editData.push({ id, putPayload, auditPayload });
                } else {
                    allData.push({
                        ...payload,
                        concept_id: CONSTANT[name === 'Right' ? 'INFECTIONHISTORYRIGHTFOLLOWUP' : 'INFECTIONHISTORYLEFTFOLLOWUP'],
                        obs_string: {
                            value: followUp !== 'Yes' ? followUp : followUpValue
                        }
                    })
                }
            }
            if (allData.length) {
                Services.PostObs(allData).then(response => {
                    if (response.status !== 400) {
                        setDiagnosisValue(response);
                        resetField();
                        openNotificationWithIcon('success', 'Medical History Submitted', '');
                    }
                })
            }
            if (editData.length) {
                editFollowUp(editData);
            }
        } catch (e) {
            console.log('Submit Follow up Exception: ', e);
        }
    }

    const setDiagnosisValue = (response = []) => {
        try {
            setFollowUpDiagnosis(response)
        } catch (e) {
            console.log('Set Diagnosis Exception: ', e);
        }
    }

    const editFollowUp = async (editData = []) => {
        try {
            editData.forEach(async data => {
                let { id, auditPayload, putPayload } = data;
                await AuditFunction.postAudit(auditPayload);
                Services.PutObsString(id, putPayload).then(response => {
                    response = {
                        ...auditPayload,
                        id,
                        obs_string: {
                            value: putPayload?.value
                        }
                    }
                    setDiagnosisValue([response]);
                })
            })
            onChange('fetchAudit');
            resetField();
        } catch (err) {
            console.log('Edit Exception :', err);
        }
    }

    const deleteFollowUp = (id, path) => {
        try {
            Services.DeleteObs(id).then(response => {
                if (response.status !== 400 && response.affected) {
                    if (path === 'followUpDiagnosis') {
                        let newData = [...followUpDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setFollowUpDiagnosis(newData);
                        }
                    }
                } else {
                    openNotificationWithIcon('error', 'Server Error')
                }
            })
        } catch (e) {
            console.log('Delete Medical History Exception: ', e);
        }
    }

    const resetField = () => {
        setFollowUp('');
        setFollowUpValue('');
    }

    return (
        <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <Card title={(<>Follow Up</>)} style={visitComplete && userInfo?.user?.person_type_id !== CONSTANT.REVIEWER ? { pointerEvents: "none", opacity: "0.4", height: '100%' } : { height: '100%' }}>
                <div>
                    <>
                        <FollowUpInline>
                            <QuestionCircleFilled style={{ color: getColor(followUpAudit?.filter(vr => getConcept(name, 'followUp') === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', getConcept(name, 'followUp'), 'Follow Up')} />
                            <div>Is follow up required?</div>
                        </FollowUpInline>
                        <Radio.Group value={followUp} onChange={(e) => onChangeHandler('followUp', e.target.value)}>
                            <Space direction="horizontal">
                                {['Yes', 'No', 'As needed']?.map((dia, index) => <Radio key={index} value={dia}>{dia}</Radio>)}
                            </Space>
                        </Radio.Group>
                    </>
                    {followUp.match('Yes') ?
                        <div>
                            <br />
                            <b>Next scheduled follow-up visit</b>
                            <DatePicker size="medium" value={followUpValue !== "" ? dayjs(followUpValue) : null} style={{ width: 350, display: 'block' }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('followUpValue', format)} />
                        </div>
                        : null}
                </div>
                {followUpDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{diagnosis.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteFollowUp', diagnosis.id, 'followUpDiagnosis')} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
                <br />
            </Card>
            {contextHolder}
        </div>
    )
}