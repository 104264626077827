import { Card, Radio, Space, AutoComplete, Divider, notification, Alert, Checkbox, Button, Input } from "antd";
import { DeleteFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import { LensWrapper, LensSection, PathalogyWrapper, PathalogySection, CornealWrapper, DiagnosisInline } from './style';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage, Server, getColor } from "../../../../Utils";
import { AuditFunction } from "../Audit/AuditFunction";

const lensDiagnosisList = ['Mature or hypermature cataract', 'Immature cataract',
    'Early lens changes', 'Clear crystalline lens', 'Pseudophakia', 'Aphakia', 'Cannot tell lens status'];

const diagnosisList1 = ['Normal', 'Conjunctival congestion/redness', 'Pterygium, defined as a vascularized lension crossing from conjunctiva onto the corneal limbus', 'Other ocular surface lesion']
const diagnosisList2 = ['Normal', 'Active corneal infection', 'Inactive corneal opacity/scar (not active infection)', 'Keratoplasty', 'Keratoconus'];

const keratoplastyList = ['PKP', 'DALK', 'DSEK', 'DMEK']

const othetPathlist = [
    { value: 'Pterygium' }, { value: 'Conjunctivitis' },
    { value: 'Subconjunctival hemorrhage' }, { value: 'Presbyopia' },
    { value: 'Active Corneal Infection' }, { value: 'Posterior Segment Screening' },
    { value: 'Cannot be assessed' }
];

const cataractTypeList = ['NS 1+', 'NS 2+', 'NS 3+', 'NS 4+ (mature)', 'CC 1+', 'CC 2+', 'CC 3+', 'CC 4+ (mature)'];
const cataractGradeList = ['PSC 1+', 'PSC 2+', 'PSC 3+', 'PSC 4+', 'n/a'];

const cornealInfectionList = [
    { value: 'No, there is not an active infection' },
    { value: 'Yes, and this is patient’s FIRST VISIT' },
    { value: 'Yes, and this is patient’s FOLLOW-UP VISIT' }
]

export const Diagnosis = ({ data, visitComplete, onChange, submit, audit, postVisitReview }) => {
    const [userInfo, setUserInfo] = useState();
    const [api, contextHolder] = notification.useNotification();

    const [rightEyeLensDiagnosis, setRightEyeLensDiagnosis] = useState([]);
    const [leftEyeLensDiagnosis, setLeftEyeLensDiagnosis] = useState([]);
    const [rightEyePathalogyDiagnosis, setRightEyePathalogyDiagnosis] = useState([]);
    const [leftEyePathalogyDiagnosis, setLeftEyePathalogyDiagnosis] = useState([]);

    const [rightCataractTypeDiagnosis, setRightCataractTypeDiagnosis] = useState([]);
    const [rightCataractGradeDiagnosis, setRightCataractGradeDiagnosis] = useState([]);
    const [leftCataractTypeDiagnosis, setLeftCataractTypeDiagnosis] = useState([]);
    const [leftCataractGradeDiagnosis, setLeftCataractGradeDiagnosis] = useState([]);
    const [cornealInfectionDiagnosis, setCornealInfectionDiagnosis] = useState([]);

    const [rightEyePathalogyCornealDiagnosis, setRightEyePathalogyCornealDiagnosis] = useState([]);
    const [leftEyePathalogyCornealDiagnosis, setLeftEyePathalogyCornealDiagnosis] = useState([]);

    const [rightEyeLens, setRightEyeLens] = useState('');
    const [rightEyeAdditionalPathology, setRightEyeAdditionalPathology] = useState([]);

    const [leftEyeLens, setLeftEyeLens] = useState('');
    const [leftEyeAdditionalPathology, setLeftEyeAdditionalPathology] = useState([]);

    const [rightCataractType, setRightCataractType] = useState([]);
    const [leftCataractType, setLeftCataractType] = useState([]);

    const [rightEyeInput, setRightEyeInput] = useState();
    const [leftEyeInput, setLeftEyeInput] = useState();


    const [cornealInfection, setCornealInfection] = useState();

    const [otherOcularRight, setOtherOcularRight] = useState();
    const [otherOcularLeft, setOtherOcularLeft] = useState();

    const [rightEyeAdditionalPathologyCorneal, setRightEyeAdditionalPathologyCorneal] = useState([]);
    const [leftEyeAdditionalPathologyCorneal, setLeftEyeAdditionalPathologyCorneal] = useState([]);

    const [keratoplastyOptionRight, setKertoplastyOptionRight] = useState();
    const [keratoplastyOptionLeft, setKertoplastyOptionLeft] = useState();

    const [diagnosisAudit, setDiagnosisAudit] = useState([]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getDiagnosis();
            getUser();
            isFirstRender.current = false
            return;
        }

        if (audit) {
            setDiagnosisAudit(audit.diagnosisAudit);
        }

        if (cornealInfection?.length || submit) {
            submitDiagnosis();
        }

        if (rightEyeLens.length || leftEyeLens.length) {
            submitDiagnosis();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cornealInfection, submit, audit, rightEyeLens, leftEyeLens])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteDiagnosis') {
            deleteDiagnosis(value, path);
        } else if (type === 'audit') {
            onChange('diagnosisAudit', value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'rightEyeLens') {
            setRightEyeLens(value);
            // if (['Mature or hypermature cataract', 'Immature cataract', 'Early lens changes'].includes(value)) {
            //     setShowCataractTypeRight(true);
            // } else {
            //     setShowCataractTypeRight(false);
            // }
        }
        if (type === 'rightEyeAdditionalPathology') {
            setRightEyeAdditionalPathology(value);
        } else if (type === 'leftEyeLens') {
            setLeftEyeLens(value);
            // if (['Mature or hypermature cataract', 'Immature cataract', 'Early lens changes'].includes(value)) {
            //     setShowCataractTypeLeft(true);
            // } else {
            //     setShowCataractTypeLeft(false);
            // }
        } else if (type === 'leftEyeAdditionalPathology') {
            setLeftEyeAdditionalPathology(value);
        } else if (type === 'cornealInfection') {
            setCornealInfection(value);
            if (value === 'Yes, and this is patient’s FIRST VISIT') {
                onChange('showFirstVisitInfection', true);
            } else {
                onChange('showFirstVisitInfection', false);
            }
        } else if (type === 'otherOcularRight') {
            setOtherOcularRight(value);
        } else if (type === 'otherOcularLeft') {
            setOtherOcularLeft(value);
        } else if (type === 'rightEyeAdditionalPathologyCorneal') {
            if (value.includes('Active corneal infection')) {
                onChange('showInfectionRight', true);
                onChange('showMedicationHistoryRight', true);
                if (Server.ENV.name === 'img') {
                    onChange('setCoordinatorVisit', true);
                }
            } else {
                onChange('showInfectionRight', false);
                onChange('showMedicationHistoryRight', false);
                if (Server.ENV.name === 'img') {
                    if (!leftEyeAdditionalPathologyCorneal.includes('Active corneal infection')) {
                        onChange('setCoordinatorVisit', false);
                    }
                }
            }
            setRightEyeAdditionalPathologyCorneal(value);
        } else if (type === 'leftEyeAdditionalPathologyCorneal') {
            if (value.includes('Active corneal infection')) {
                onChange('showInfectionLeft', true);
                onChange('showMedicationHistoryLeft', true);
                if (Server.ENV.name !== 'img') {
                    onChange('setCoordinatorVisit', true);
                }
            } else {
                onChange('showInfectionLeft', false);
                onChange('showMedicationHistoryLeft', false);
                if (Server.ENV.name === 'img') {
                    if (!rightEyeAdditionalPathologyCorneal.includes('Active corneal infection')) {
                        onChange('setCoordinatorVisit', false);
                    }
                }
            }
            setLeftEyeAdditionalPathologyCorneal(value);
        } else if (type === 'keratoplastyOptionRight') {
            setKertoplastyOptionRight(value);
        } else if (type === 'keratoplastyOptionLeft') {
            setKertoplastyOptionLeft(value);
        }
    }

    const getDiagnosis = async () => {
        setRightEyeLensDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS) || []);
        setLeftEyeLensDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS) || []);
        setRightEyePathalogyDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY) || []);
        setLeftEyePathalogyDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY) || []);
        setRightCataractTypeDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPERIGHT) || []);
        setRightCataractGradeDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADERIGHT) || []);
        setLeftCataractTypeDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPELEFT) || []);
        setLeftCataractGradeDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADELEFT) || []);
        setCornealInfectionDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCOREALINFECTION) || [])

        setRightEyePathalogyCornealDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYECORNEAL) || []);
        setLeftEyePathalogyCornealDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYECORNEAL) || []);
    }

    const submitDiagnosis = async () => {
        let allData = [], editData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: userInfo.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (rightEyeLens) {
            if (rightEyeLensDiagnosis.length) {
                let id = rightEyeLensDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: rightEyeLensDiagnosis[0]?.id,
                    value: rightEyeLens
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISRIGHTEYELENS,
                    status: 'Answered',
                    value: rightEyeLensDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload })
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISRIGHTEYELENS,
                    obs_string: {
                        value: rightEyeLens
                    }
                })
            }
        } else if (!rightEyeLensDiagnosis.length) {
            // openNotificationWithIcon('error', 'Select the Right Eye Lens Status');
            // return;
        }
        if (rightEyeAdditionalPathology.length) {
            let rightEyePathValue = [...rightEyeAdditionalPathology], processedString = '';
            let checkInput = rightEyePathValue.indexOf(4);
            if (checkInput !== -1) {
                processedString += `${rightEyeInput}, `;
                rightEyePathValue.splice(checkInput, 1);
            }
            let checkOthers = rightEyePathValue.indexOf('Other ocular surface lesion');
            if (checkOthers !== -1) {
                processedString += `${otherOcularRight}, `;
                rightEyePathValue.splice(checkOthers, 1);
            }
            processedString += rightEyePathValue.join(', ');
            if (rightEyePathalogyDiagnosis.length) {
                let id = rightEyePathalogyDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: rightEyePathalogyDiagnosis[0]?.id,
                    value: processedString
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY,
                    status: 'Answered',
                    value: rightEyePathalogyDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY,
                    obs_string: {
                        value: processedString
                    }
                })
            }
        }
        if (leftEyeLens) {
            if (leftEyeLensDiagnosis.length) {
                let id = leftEyeLensDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: leftEyeLensDiagnosis[0]?.id,
                    value: leftEyeLens
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISLEFTEYELENS,
                    status: 'Answered',
                    value: leftEyeLensDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISLEFTEYELENS,
                    obs_string: {
                        value: leftEyeLens
                    }
                })
            }
        } else if (!leftEyeLensDiagnosis.length) {
            // openNotificationWithIcon('error', 'Select the Left Eye Lens Status');
            // return;
        }
        if (leftEyeAdditionalPathology.length) {
            let leftEyePathValue = [...leftEyeAdditionalPathology], processedString = '';
            // let checkInput = leftEyePathValue.indexOf(4);
            // if (checkInput !== -1) {
            //     processedString += `${leftEyeInput}, `;
            //     leftEyePathValue.splice(checkInput, 1);
            // }
            let checkOthers = leftEyePathValue.indexOf('Other ocular surface lesion');
            if (checkOthers !== -1) {
                processedString += `${otherOcularLeft}, `;
                leftEyePathValue.splice(checkOthers, 1);
            }
            processedString += leftEyePathValue.join(', ');
            if (leftEyePathalogyDiagnosis.length) {
                let id = leftEyePathalogyDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: leftEyePathalogyDiagnosis[0]?.id,
                    value: processedString
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY,
                    status: 'Answered',
                    value: rightEyePathalogyDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY,
                    obs_string: {
                        value: processedString
                    }
                })
            }
        }
        if (rightEyeAdditionalPathologyCorneal.length) {
            let rightEyePathValue = [...rightEyeAdditionalPathologyCorneal], processedString = '';
            let checkInput = rightEyePathValue.indexOf(4);
            if (checkInput !== -1) {
                processedString += `${rightEyeInput}, `;
                rightEyePathValue.splice(checkInput, 1);
            }
            let checkKero = rightEyePathValue.indexOf('Keratoplasty');
            if (checkKero !== -1) {
                processedString += `Keratoplasty - ${keratoplastyOptionRight}, `;
                rightEyePathValue.splice(checkKero, 1);
            }
            processedString += rightEyePathValue.join(', ');
            if (rightEyePathalogyCornealDiagnosis.length) {
                let id = rightEyePathalogyCornealDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: rightEyePathalogyCornealDiagnosis[0]?.id,
                    value: processedString
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISRIGHTEYECORNEAL,
                    status: 'Answered',
                    value: rightEyePathalogyCornealDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISRIGHTEYECORNEAL,
                    obs_string: {
                        value: processedString
                    }
                })
            }
        }
        if (leftEyeAdditionalPathologyCorneal.length) {
            let leftEyePathValue = [...leftEyeAdditionalPathologyCorneal], processedString = '';
            let checkInput = leftEyePathValue.indexOf(4);
            if (checkInput !== -1) {
                processedString += `${leftEyeInput}, `;
                leftEyePathValue.splice(checkInput, 1);
            }
            let checkKero = leftEyePathValue.indexOf('Keratoplasty');
            if (checkKero !== -1) {
                processedString += `Keratoplasty - ${keratoplastyOptionLeft}, `;
                leftEyePathValue.splice(checkKero, 1);
            }
            processedString += leftEyePathValue.join(', ');
            if (leftEyePathalogyCornealDiagnosis.length) {
                let id = leftEyePathalogyCornealDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: leftEyePathalogyCornealDiagnosis[0]?.id,
                    value: processedString
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISLEFTEYECORNEAL,
                    status: 'Answered',
                    value: leftEyePathalogyCornealDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISLEFTEYECORNEAL,
                    obs_string: {
                        value: processedString
                    }
                })
            }
        }
        if (leftCataractType.length) {
            if (leftCataractTypeDiagnosis.length) {
                let id = leftCataractTypeDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: leftCataractTypeDiagnosis[0]?.id,
                    value: leftCataractType.join(', ')
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISCATARACTTYPELEFT,
                    status: 'Answered',
                    value: leftCataractTypeDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISCATARACTTYPELEFT,
                    obs_string: {
                        value: leftCataractType.join(', ')
                    }
                })
            }
        }
        if (rightCataractType.length) {
            if (rightCataractTypeDiagnosis.length) {
                let id = rightCataractTypeDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: rightCataractTypeDiagnosis[0]?.id,
                    value: rightCataractType.join(', ')
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISCATARACTTYPERIGHT,
                    status: 'Answered',
                    value: rightCataractTypeDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISCATARACTTYPERIGHT,
                    obs_string: {
                        value: rightCataractType.join(', ')
                    }
                })
            }
        }
        if (cornealInfection) {
            if (cornealInfectionDiagnosis.length) {
                let id = cornealInfectionDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: cornealInfectionDiagnosis[0]?.id,
                    value: cornealInfection
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISCOREALINFECTION,
                    status: 'Answered',
                    value: cornealInfectionDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.DIAGNOSISCOREALINFECTION,
                    obs_string: {
                        value: cornealInfection
                    }
                })
            }
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    setDiagnosisValue(response);
                    resetField();
                    openNotificationWithIcon('success', 'Diagnosis Submitted');
                }
            })
        } else {
            // openNotificationWithIcon('error', 'No Diagnosis');
        }
        if (editData.length) {
            editDiagnosis(editData);
        }
    }

    const setDiagnosisValue = (response = []) => {
        try {
            let rightEyeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS);
            let leftEyeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS);
            let rightEyeD = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY);
            let leftEyeD = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY);
            let conTypeR = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPERIGHT);
            // let conGradR = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADERIGHT);
            let conTypeL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTTYPELEFT);
            // let conGradL = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCATARACTGRADELEFT);
            let corneal = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISCOREALINFECTION);
            let cornealRight = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISRIGHTEYECORNEAL);
            let cornealLeft = response.filter(ob => ob.concept_id === CONSTANT.DIAGNOSISLEFTEYECORNEAL);
            if (rightEyeL.length) {
                setRightEyeLensDiagnosis(rightEyeL);
            }
            if (rightEyeD.length) {
                setRightEyePathalogyDiagnosis(rightEyeD);
            }
            if (leftEyeL.length) {
                setLeftEyeLensDiagnosis(leftEyeL);
            }
            if (leftEyeD.length) {
                setLeftEyePathalogyDiagnosis(leftEyeD);
            }
            if (conTypeR.length) {
                setRightCataractTypeDiagnosis(conTypeR);
            }
            // if (conGradR.length) {
            //     setRightCataractGradeDiagnosis([...rightCataractGradeDiagnosis, ...conGradR]);
            // }
            if (conTypeL.length) {
                setLeftCataractTypeDiagnosis(conTypeL);
            }
            // if (conGradL.length) {
            //     setLeftCataractGradeDiagnosis([...leftCataractGradeDiagnosis, ...conGradL]);
            // }
            if (corneal.length) {
                setCornealInfectionDiagnosis(corneal);
            }
            if (cornealRight.length) {
                setRightEyePathalogyCornealDiagnosis(cornealRight);
            }
            if (cornealLeft.length) {
                setLeftEyePathalogyCornealDiagnosis(cornealLeft);
            }
        } catch (e) {
            console.log('Set Diagnosis Exception: ', e);
        }
    }

    const editDiagnosis = async (editData = []) => {
        try {
            editData.forEach(async data => {
                let { id, auditPayload, putPayload } = data;
                await AuditFunction.postAudit(auditPayload);
                Services.PutObsString(id, putPayload).then(response => {
                    response = {
                        ...auditPayload,
                        id,
                        obs_string: {
                            value: putPayload?.value
                        }
                    }
                    setDiagnosisValue([response]);
                })
            })
            onChange('fetchAudit');
            resetField();
        } catch (err) {
            console.log('Edit Exception :', err);
        }
    }

    const deleteDiagnosis = (id, path) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                if (path === 'rightEyeLensDiagnosis') {
                    let newData = [...rightEyeLensDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightEyeLensDiagnosis(newData);
                    }
                } else if (path === 'leftEyeLensDiagnosis') {
                    let newData = [...leftEyeLensDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftEyeLensDiagnosis(newData);
                    }
                } else if (path === 'rightEyePathalogyDiagnosis') {
                    let newData = [...rightEyePathalogyDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightEyePathalogyDiagnosis(newData);
                    }
                } else if (path === 'leftEyePathalogyDiagnosis') {
                    let newData = [...leftEyePathalogyDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftEyePathalogyDiagnosis(newData);
                    }
                } else if (path === 'rightCataractTypeDiagnosis') {
                    let newData = [...rightCataractTypeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightCataractTypeDiagnosis(newData);
                    }
                } else if (path === 'rightCataractGradeDiagnosis') {
                    let newData = [...rightCataractGradeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightCataractGradeDiagnosis(newData);
                    }
                } else if (path === 'leftCataractTypeDiagnosis') {
                    let newData = [...leftCataractTypeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftCataractTypeDiagnosis(newData);
                    }
                } else if (path === 'leftCataractGradeDiagnosis') {
                    let newData = [...leftCataractGradeDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftCataractGradeDiagnosis(newData);
                    }
                } else if (path === 'cornealInfectionDiagnosis') {
                    let newData = [...cornealInfectionDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setCornealInfectionDiagnosis(newData);
                    }
                } else if (path === 'rightEyePathalogyCornealDiagnosis') {
                    let newData = [...rightEyePathalogyCornealDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setRightEyePathalogyCornealDiagnosis(newData);
                    }
                } else if (path === 'leftEyePathalogyCornealDiagnosis') {
                    let newData = [...leftEyePathalogyCornealDiagnosis];
                    let index = newData.findIndex(dt => dt.id === id);
                    if (index !== -1) {
                        newData.splice(index, 1);
                        setLeftEyePathalogyCornealDiagnosis(newData);
                    }
                }
                openNotificationWithIcon('success', 'Diagnosis Deleted');
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setRightEyeLens('');
        setRightEyeAdditionalPathology([]);
        setRightEyeInput('');
        setLeftEyeLens('')
        setLeftEyeAdditionalPathology([]);
        setLeftEyeInput('');
        setLeftCataractType('');
        setRightCataractType('');
        setCornealInfection('');
        setOtherOcularRight('');
        setOtherOcularLeft('');
        setRightEyeAdditionalPathologyCorneal('');
        setLeftEyeAdditionalPathologyCorneal('');
        setKertoplastyOptionLeft('');
        setKertoplastyOptionRight('');
    }

    return (
        <>
            <Card title={(<>Physician Diagnosis</>)} style={visitComplete && userInfo?.user?.person_type_id !== CONSTANT.REVIEWER ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                <h4 style={{ marginTop: 0 }}>Lens Status <span>(required)</span></h4>
                <LensWrapper>
                    <div style={{ width: '100%' }}>
                        <DiagnosisInline>
                            <QuestionCircleFilled style={{ color: getColor(diagnosisAudit?.filter(vr => CONSTANT.DIAGNOSISRIGHTEYELENS === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.DIAGNOSISRIGHTEYELENS, 'Lens Status Right')} />
                            <div>Right Eye</div>
                        </DiagnosisInline>
                        <Divider />
                        <LensSection>
                            <div style={{ width: '75%' }}>
                                {/* <h4 style={{ marginTop: 0 }}>Lens Status <span>(required)</span></h4> */}
                                <Radio.Group value={rightEyeLens} onChange={(e) => onChangeHandler('rightEyeLens', e.target.value)}>
                                    <Space direction="vertical">
                                        {lensDiagnosisList?.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                    </Space>
                                </Radio.Group>
                            </div>
                        </LensSection>

                        {rightEyeLensDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightEyeLensDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </div>

                    <div style={{ width: '100%' }}>
                        <DiagnosisInline>
                            <QuestionCircleFilled style={{ color: getColor(diagnosisAudit?.filter(vr => CONSTANT.DIAGNOSISLEFTEYELENS === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.DIAGNOSISLEFTEYELENS, 'Lens Status Left')} />
                            <div>Left Eye</div>
                        </DiagnosisInline>
                        <Divider />
                        <LensSection>
                            <div style={{ width: '75%' }}>
                                {/* <h4 style={{ marginTop: 0 }}>Lens Status <span>(required)</span></h4> */}
                                <Radio.Group value={leftEyeLens} onChange={(e) => onChangeHandler('leftEyeLens', e.target.value)}>
                                    <Space direction="vertical">
                                        {lensDiagnosisList?.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                    </Space>
                                </Radio.Group>
                            </div>
                        </LensSection>

                        {leftEyeLensDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftEyeLensDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>)}

                    </div>
                </LensWrapper>

                <Divider />

                <h4 style={{ marginTop: 0 }}>Ocular Surface Lesion</h4>
                <PathalogyWrapper>
                    <div style={{ width: '100%' }}>
                        <DiagnosisInline>
                            <QuestionCircleFilled style={{ color: getColor(diagnosisAudit?.filter(vr => CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY, 'Ocular Surface Lesion Right')} />
                            <div>Right Eye</div>
                        </DiagnosisInline>
                        <Divider />
                        <PathalogySection>
                            <div style={{ width: '100%' }}>
                                <Checkbox.Group onChange={(e) => onChangeHandler('rightEyeAdditionalPathology', e)} value={rightEyeAdditionalPathology} style={{ width: '50%' }}>
                                    <Space direction="vertical">
                                        {diagnosisList1?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                        {rightEyeAdditionalPathology.includes('Other ocular surface lesion') ? <Input placeholder="Name" value={otherOcularRight} onChange={(e) => onChangeHandler('otherOcularRight', e.target.value)} /> : null}
                                    </Space>
                                </Checkbox.Group>
                            </div>
                        </PathalogySection>
                        {rightEyePathalogyDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightEyePathalogyDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )}
                    </div>
                    <div style={{ width: '100%' }}>
                        <DiagnosisInline>
                            <QuestionCircleFilled style={{ color: getColor(diagnosisAudit?.filter(vr => CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY, 'Ocular Surface Lesion Left')} />
                            <div>Left Eye</div>
                        </DiagnosisInline>
                        <Divider />
                        <PathalogySection>
                            <div style={{ width: '100%' }}>
                                <Checkbox.Group onChange={(e) => onChangeHandler('leftEyeAdditionalPathology', e)} value={leftEyeAdditionalPathology} style={{ width: '50%' }}>
                                    <Space direction="vertical">
                                        {diagnosisList1?.map((list, index) => <Checkbox key={index} value={list}>{list}</Checkbox>)}
                                        {leftEyeAdditionalPathology.includes('Other ocular surface lesion') ? <Input placeholder="Name" value={otherOcularLeft} onChange={(e) => onChangeHandler('otherOcularLeft', e.target.value)} /> : null}
                                    </Space>
                                </Checkbox.Group>
                                {leftEyePathalogyDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                    (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftEyePathalogyDiagnosis')} style={{ color: 'red' }} />
                                        </div>)
                                } /></div>
                                )}
                            </div>
                        </PathalogySection>
                    </div>
                </PathalogyWrapper>

                <Divider />
                <h4 style={{ marginTop: 0 }}>Corneal Abnormality</h4>
                <PathalogyWrapper>
                    <div style={{ width: '100%' }}>
                        <DiagnosisInline>
                            <QuestionCircleFilled style={{ color: getColor(diagnosisAudit?.filter(vr => CONSTANT.DIAGNOSISRIGHTEYECORNEAL === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.DIAGNOSISRIGHTEYECORNEAL, 'Corneal Abnormality Right')} />
                            <div>Right Eye</div>
                        </DiagnosisInline>
                        <Divider />
                        <PathalogySection>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', gap: '5%' }}>
                                    <Checkbox.Group onChange={(e) => onChangeHandler('rightEyeAdditionalPathologyCorneal', e)} value={rightEyeAdditionalPathologyCorneal} style={{ width: '50%' }}>
                                        <Space direction="vertical">
                                            {diagnosisList2?.map((list) => <Checkbox key={list} value={list}>{list}</Checkbox>)}
                                            <Checkbox value={4}>Other corneal abnormality</Checkbox>
                                            {rightEyeAdditionalPathologyCorneal.includes(4) ?
                                                <AutoComplete
                                                    options={othetPathlist}
                                                    value={rightEyeInput} onChange={e => setRightEyeInput(e)}
                                                    style={{ width: 200, marginLeft: 10 }}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                                </AutoComplete> : null}
                                        </Space>
                                    </Checkbox.Group>
                                    {rightEyeAdditionalPathologyCorneal.includes('Keratoplasty') ?
                                        <Radio.Group onChange={(e) => onChangeHandler('keratoplastyOptionRight', e.target.value)}>
                                            <Space direction="vertical">
                                                {keratoplastyList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                                            </Space>
                                        </Radio.Group> : null}
                                </div>
                                {rightEyePathalogyCornealDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                    (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'rightEyePathalogyCornealDiagnosis')} style={{ color: 'red' }} />
                                        </div>)
                                } /></div>
                                )}
                            </div>
                        </PathalogySection>
                    </div>
                    <div style={{ width: '100%' }}>
                        <DiagnosisInline>
                            <QuestionCircleFilled style={{ color: getColor(diagnosisAudit?.filter(vr => CONSTANT.DIAGNOSISLEFTEYECORNEAL === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.DIAGNOSISLEFTEYECORNEAL, 'Corneal Abnormality Left')} />
                            <div>Left Eye</div>
                        </DiagnosisInline>
                        <Divider />
                        <PathalogySection>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', gap: '5%' }}>
                                    <Checkbox.Group onChange={(e) => onChangeHandler('leftEyeAdditionalPathologyCorneal', e)} value={leftEyeAdditionalPathologyCorneal} style={{ width: '50%' }}>
                                        <Space direction="vertical">
                                            {diagnosisList2?.map((list) => <Checkbox key={list} value={list}>{list}</Checkbox>)}
                                            <Checkbox value={4}>Other corneal abnormality</Checkbox>
                                            {leftEyeAdditionalPathologyCorneal.includes(4) ?
                                                <AutoComplete
                                                    options={othetPathlist}
                                                    value={leftEyeInput} onChange={e => setLeftEyeInput(e)}
                                                    style={{ width: 200, marginLeft: 10 }}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                                </AutoComplete> : null}
                                        </Space>
                                    </Checkbox.Group>
                                    {leftEyeAdditionalPathologyCorneal.includes('Keratoplasty') ?
                                        <Radio.Group onChange={(e) => onChangeHandler('keratoplastyOptionLeft', e.target.value)}>
                                            <Space direction="vertical">
                                                {keratoplastyList?.map(lt => <Radio key={lt} value={lt}>{lt}</Radio>)}
                                            </Space>
                                        </Radio.Group> : null}
                                </div>
                                {leftEyePathalogyCornealDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                    (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'leftEyePathalogyCornealDiagnosis')} style={{ color: 'red' }} />
                                        </div>)
                                } /></div>
                                )}
                            </div>
                        </PathalogySection>
                    </div>
                </PathalogyWrapper>

                <Divider />

                <DiagnosisInline>
                    <QuestionCircleFilled style={{ marginTop: -18, color: getColor(diagnosisAudit?.filter(vr => CONSTANT.DIAGNOSISCOREALINFECTION === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.DIAGNOSISCOREALINFECTION, 'Corneal Infection')} />
                    <h4 style={{ marginTop: 0 }}>Corneal Infection</h4>
                </DiagnosisInline>
                <CornealWrapper>
                    <h5>Is there an active corneal infection in either eye?</h5>
                    <Radio.Group onChange={(e) => onChangeHandler('cornealInfection', e.target.value)} value={cornealInfection}>
                        <Space direction="vertical">
                            {cornealInfectionList?.map((corneal, index) => <Radio key={index} value={corneal.value}>{corneal.value}</Radio>)}
                        </Space>
                    </Radio.Group>
                    {cornealInfectionDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                        (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>{diagnosis.obs_string.value}</div>
                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'cornealInfectionDiagnosis')} style={{ color: 'red' }} />
                            </div>)
                    } /></div>
                    )}
                    <br />
                </CornealWrapper>
                {/* <div style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={submitDiagnosis}>Submit Diagnosis</Button>
                </div> */}
            </Card >
            {contextHolder}
        </>
    )
}