import { useEffect, useRef, useState } from "react";
import { Services } from '../../../../__services';
import { ImageWrapper, ImageSection, Image, PdfWrapper, PreviewCard } from './style';
import { CloudDownloadOutlined } from '@ant-design/icons';

export const PatientImage = ({ data }) => {
    const isFirstRender = useRef(true);
    const [allImages, setAllImages] = useState([]);

    useEffect(() => {
        if (isFirstRender.current) {
            getImage();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getImage = async () => {
        try {
            let params = `patient_id=${data.patient_id}&visit_id=${data.visit_id}`;
            let patientImage = await Services.GetImageWithParams(params);
            setAllImages(patientImage.rows);
        } catch (err) {
            console.log('Patient Image Exception :', err);
        }
    }

    return (
        <>
            <ImageWrapper>
                <ImageSection>
                    {allImages.map(image =>
                        image.type.match('right') ? <a href={image.image_path} key={image.id}><Image src={image.image_path} alt="" /></a> : null
                    )}
                </ImageSection >
                <ImageSection>
                    {allImages.map(image =>
                        image.type.match('left') ? <a href={image.image_path} key={image.id}><Image src={image.image_path} alt="" /></a> : null
                    )}
                </ImageSection >
            </ImageWrapper >
            <PdfWrapper>
                {allImages.map(img => img?.image_name?.split('.').pop() === 'pdf' ?
                    <PreviewCard href={img?.image_path} target="_blank" rel="noopener noreferrer">
                        <CloudDownloadOutlined style={{ fontSize: 48 }} />
                        <div>
                            <div>{img?.image_name}</div>
                            <div style={{color: 'black'}}>Paper Form and Consent Form</div>
                        </div>
                    </PreviewCard> : null
                )}
            </PdfWrapper >
        </>
    )
}