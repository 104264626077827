import { useState, useEffect, useRef } from "react";
import { Card, AutoComplete, Input, Space, notification, Alert, Radio, Checkbox, Button, DatePicker, Divider } from "antd";
import { DeleteFilled, QuestionCircleFilled } from '@ant-design/icons';
import { EMRWrapper, EMRSection, EMRInline } from './style';
import { Services } from "../../../../__services";
import { CONSTANT, getFromLocalStorage, Server, useOnScreen, getColor } from "../../../../Utils";
import dayjs from 'dayjs';
import { AuditFunction } from "../Audit/AuditFunction";

// const visualAndPinoleData = [{ value: "6/6" }, { value: "6/9" }, { value: "6/12" }, { value: "6/18" }, { value: "6/36" }, { value: "6/60" }, { value: "5/60" }, { value: "4/60" }, { value: "3/60" },
// { value: "2/60" }, { value: "1/60" }, { value: "Finger Counts" }, { value: "Hand movements" }, { value: "Light Perception" }, { value: "No Light Perception" }];


const visualAndPinoleData = [{ value: "6/6" },
{ value: "6/6P" },
{ value: "6/9" },
{ value: "6/9P" },
{ value: "6/12" },
{ value: "6/12P" },
{ value: "6/18" },
{ value: "6/18P" },
{ value: "6/24" },
{ value: "6/24P" },
{ value: "6/36" },
{ value: "3/36P" },
{ value: "6/60" },
{ value: "6/60P" },
{ value: "5/60" },
{ value: "4/60" },
{ value: "3/60" },
{ value: "2/60" },
{ value: "1/60" },
{ value: "1/2/60" },
{ value: "Finger Counts" },
{ value: "Hand movements" },
{ value: "Light Perception" },
{ value: "No Light Perception" },
];

const vaMeasurementList = [
    'Best spectacle-corrected (manifested refraction)',
    'With eyeglass correction', 'Without any correction', 'Other VA type']

const lacrimalDuctData = ["Free", "Not free with clear fluid", "Not free with pus", "n/a"];

const referralList = ['Walk-in', 'Referral from Vision Center', 'Referral from ophthalmologist/eye care practitioner'];

export const EMR = ({ data, visitComplete, showInfectionFragmentRight, submit, onChange, audit }) => {
    const [userInfo, setUserInfo] = useState();
    const [api, contextHolder] = notification.useNotification();

    const [pinholeRightDignosis, setPinholeRightDignosis] = useState([]);
    const [pinholeLeftDignosis, setPinholeLeftDignosis] = useState([]);
    const [acuityRightDignosis, setAcuityRightDignosis] = useState([]);
    const [acuityLeftDignosis, setAcuityLeftDignosis] = useState([]);
    const [iopRightDiagnosis, setIopRightDiagnosis] = useState([]);
    const [iopLeftDiagnosis, setIopLeftDiagnosis] = useState([]);
    const [lacrimalDuctRightDiagnosis, setLacrimalDuctRightDiagnosis] = useState([]);
    const [lacrimalDuctLeftDiagnosis, setLacrimalDuctLeftDiagnosis] = useState([]);
    const [bloodSugarDiagnosis, setBloodSugarDiagnosis] = useState([]);
    const [vaMeasurementRightDiagnosis, setVaMeasurementRightDiagnosis] = useState([]);
    const [vaMeasurementLeftDiagnosis, setVaMeasurementLeftDiagnosis] = useState([]);
    const [referralDiagnosis, setReferralDiagnosis] = useState([]);
    // const [followUpDiagnosis, setFollowUpDiagnosis] = useState([]);

    const [rightAll, setRightAll] = useState(false);
    const [leftAll, setLeftAll] = useState(false);
    const [visualRight, setVisualRight] = useState('');
    const [visualLeft, setVisualLeft] = useState('');
    const [pinholeRight, setPinholeRight] = useState('');
    const [pinholeLeft, setPinholeLeft] = useState('');
    const [iopRight, setIopRight] = useState();
    const [iopLeft, setIopLeft] = useState();
    const [lacrimalDuctRight, setLacrimalDuctRight] = useState('');
    const [lacrimalDuctLeft, setLacrimalDuctLeft] = useState('');
    const [bloodSugar, setBloodSugar] = useState('');
    const [vaMeasurementRight, setVaMeasurementRight] = useState('');
    const [vaMeasurementLeft, setVaMeasurementLeft] = useState('');
    const [vaOtherLeft, setVaOtherLeft] = useState('');
    const [vaOtherRight, setVaOtherRight] = useState('');
    const [referral, setReferral] = useState('');
    const [EMRAudit, setEMRAudit] = useState([]);
    // const [followUp, setFollowUP] = useState('');
    const isFirstRender = useRef(true);

    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

    useEffect(() => {
        if (isFirstRender.current) {
            getEMR();
            getUser();
            isFirstRender.current = false
            return;
        }

        if (audit) {
            setEMRAudit(audit.EMRAudit);
        }

        if ((lacrimalDuctRight.length && lacrimalDuctLeft.length) || submit) {
            submitEMR();
        }

        if (vaMeasurementLeft.length || vaMeasurementRight.length) {
            submitEMR();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lacrimalDuctRight, lacrimalDuctLeft, submit, audit, vaMeasurementLeft, vaMeasurementRight])

    const getUser = async () => {
        let user = await getFromLocalStorage('user');
        setUserInfo(user);
    }

    const onClickHandler = (type, value, path) => {
        if (type === 'deleteDiagnosis') {
            deleteEMR(value, path);
        } else if (type === 'audit') {
            onChange('EMRAudit', value, path);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'lacrimalDuctRight') {
            setLacrimalDuctRight(value);
        } else if (type === 'lacrimalDuctLeft') {
            setLacrimalDuctLeft(value);
        } else if (type === 'vaMeasurementRight') {
            setVaMeasurementRight(value);
        } else if (type === 'vaMeasurementLeft') {
            setVaMeasurementLeft(value);
        } else if (type === 'referral') {
            setReferral(value);
        } else if (type === 'rightAll') {
            if (value) {
                setRightAll(true);
                setVisualRight('n/a');
                setPinholeRight('n/a');
                setIopRight('n/a');
            } else {
                setRightAll(false);
                setVisualRight('');
                setPinholeRight('');
                setIopRight();
            }
        } else if (type === 'leftAll') {
            if (value) {
                setLeftAll(true);
                setVisualLeft('n/a');
                setPinholeLeft('n/a');
                setIopLeft('n/a')
            } else {
                setLeftAll(false);
                setVisualLeft('');
                setPinholeLeft('');
                setIopLeft();
            }
        }
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const getEMR = async () => {
        setAcuityLeftDignosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.VALEFT) || []);
        setAcuityRightDignosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.VARIGHT) || []);
        setPinholeLeftDignosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.PINEHOLELEFT) || []);
        setPinholeRightDignosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.PINEHOLERIGHT) || []);
        setIopLeftDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRIOPLEFT) || []);
        setIopRightDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRIOPRIGHT) || []);
        setBloodSugarDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRBLOODSUGAR) || []);
        setLacrimalDuctRightDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRLACRIMALDUCTRIGHT) || []);
        setLacrimalDuctLeftDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRLACRIMALDUCTLEFT) || []);
        setVaMeasurementRightDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRVAMEASUREMENTRIGHT) || []);
        setVaMeasurementLeftDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRVAMEASUREMENTLEFT) || []);
        setReferralDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.EMRREFERRAL) || []);
        // setFollowUpDiagnosis(data?.obs?.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYRIGHTFOLLOWUP));
    }

    const submitEMR = async () => {
        let allData = [], editData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: userInfo.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (visualRight.length) {
            if (acuityRightDignosis.length) {
                let id = acuityRightDignosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: acuityRightDignosis[0]?.id,
                    value: visualRight
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.VARIGHT,
                    status: 'Answered',
                    value: acuityRightDignosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.VARIGHT,
                    obs_string: {
                        value: visualRight
                    }
                })
            }
        }
        if (visualLeft.length) {
            if (acuityLeftDignosis.length) {
                let id = acuityLeftDignosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: acuityLeftDignosis[0]?.id,
                    value: visualLeft
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.VALEFT,
                    status: 'Answered',
                    value: acuityLeftDignosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.VALEFT,
                    obs_string: {
                        value: visualLeft
                    }
                })
            }
        }
        if (pinholeRight.length) {
            if (pinholeRightDignosis.length) {
                let id = pinholeRightDignosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: pinholeRightDignosis[0]?.id,
                    value: pinholeRight
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.PINEHOLERIGHT,
                    status: 'Answered',
                    value: pinholeRightDignosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.PINEHOLERIGHT,
                    obs_string: {
                        value: pinholeRight
                    }
                })
            }
        }
        if (pinholeLeft.length) {
            if (pinholeLeftDignosis.length) {
                let id = pinholeLeftDignosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: pinholeLeftDignosis[0]?.id,
                    value: pinholeLeft
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.PINEHOLELEFT,
                    status: 'Answered',
                    value: pinholeLeftDignosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.PINEHOLELEFT,
                    obs_string: {
                        value: pinholeLeft
                    }
                })
            }
        }
        if (iopRight) {
            if (iopRightDiagnosis.length) {
                let id = iopRightDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: iopRightDiagnosis[0]?.id,
                    value: iopRight
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRIOPRIGHT,
                    status: 'Answered',
                    value: iopRightDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRIOPRIGHT,
                    obs_string: {
                        value: iopRight
                    }
                })
            }
        }
        if (iopLeft) {
            if (iopLeftDiagnosis.length) {
                let id = iopLeftDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: iopLeftDiagnosis[0]?.id,
                    value: iopLeft
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRIOPLEFT,
                    status: 'Answered',
                    value: iopLeftDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload })
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRIOPLEFT,
                    obs_string: {
                        value: iopLeft
                    }
                })
            }
        }
        if (bloodSugar.length) {
            if (bloodSugarDiagnosis.length) {
                let id = bloodSugarDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: bloodSugarDiagnosis[0]?.id,
                    value: `${bloodSugar} mg/dL`
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRBLOODSUGAR,
                    status: 'Answered',
                    value: bloodSugarDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRBLOODSUGAR,
                    obs_string: {
                        value: `${bloodSugar} mg/dL`
                    }
                })
            }
        }
        if (lacrimalDuctRight.length) {
            if (lacrimalDuctRightDiagnosis.length) {
                let id = lacrimalDuctRightDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: lacrimalDuctRightDiagnosis[0]?.id,
                    value: lacrimalDuctRight
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRLACRIMALDUCTRIGHT,
                    status: 'Answered',
                    value: lacrimalDuctRightDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRLACRIMALDUCTRIGHT,
                    obs_string: {
                        value: lacrimalDuctRight
                    }
                })
            }
        }
        if (lacrimalDuctLeft.length) {
            if (lacrimalDuctLeftDiagnosis.length) {
                let id = lacrimalDuctLeftDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: lacrimalDuctLeftDiagnosis[0]?.id,
                    value: lacrimalDuctLeft
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRLACRIMALDUCTLEFT,
                    status: 'Answered',
                    value: lacrimalDuctLeftDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRLACRIMALDUCTLEFT,
                    obs_string: {
                        value: lacrimalDuctLeft
                    }
                })
            }
        }
        if (vaMeasurementRight.length) {
            if (vaMeasurementRightDiagnosis.length) {
                let id = vaMeasurementRightDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: vaMeasurementRightDiagnosis[0]?.id,
                    value: vaMeasurementRight === 'Other VA type' ? vaOtherRight : vaMeasurementRight
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRVAMEASUREMENTRIGHT,
                    status: 'Answered',
                    value: vaMeasurementRightDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRVAMEASUREMENTRIGHT,
                    obs_string: {
                        value: vaMeasurementRight === 'Other VA type' ? vaOtherRight : vaMeasurementRight
                    }
                })
            }
        }
        if (vaMeasurementLeft.length) {
            if (vaMeasurementLeftDiagnosis.length) {
                let id = vaMeasurementLeftDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: vaMeasurementLeftDiagnosis[0]?.id,
                    value: vaMeasurementLeft === 'Other VA type' ? vaOtherLeft : vaMeasurementLeft
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRVAMEASUREMENTLEFT,
                    status: 'Answered',
                    value: vaMeasurementLeftDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRVAMEASUREMENTLEFT,
                    obs_string: {
                        value: vaMeasurementLeft === 'Other VA type' ? vaOtherLeft : vaMeasurementLeft
                    }
                })
            }
        }
        if (referral.length) {
            if (referralDiagnosis.length) {
                let id = referralDiagnosis[0]?.obs_string?.id
                let putPayload = {
                    obs_id: referralDiagnosis[0]?.id,
                    value: referral
                }
                let auditPayload = {
                    ...payload,
                    concept_id: CONSTANT.EMRREFERRAL,
                    status: 'Answered',
                    value: referralDiagnosis[0]?.obs_string?.value
                }
                editData.push({ id, putPayload, auditPayload });
            } else {
                allData.push({
                    ...payload,
                    concept_id: CONSTANT.EMRREFERRAL,
                    obs_string: {
                        value: referral
                    }
                })
            }
        }
        // if (followUp) {
        //     allData.push({
        //         ...payload,
        //         concept_id: CONSTANT.INFECTIONHISTORYRIGHTFOLLOWUP,
        //         obs_string: {
        //             value: followUp
        //         }
        //     })
        // }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    setDiagnosisValue(response)
                    // if (follw.length) {
                    //     setFollowUpDiagnosis([...followUpDiagnosis, ...follw]);
                    // }
                    resetField();
                    openNotificationWithIcon('success', 'EMR Submitted');
                }
            })
        }
        if (editData.length) {
            editEMR(editData);
        }
    }

    const setDiagnosisValue = (response = []) => {
        try {
            let vaL = response.filter(ob => ob.concept_id === CONSTANT.VALEFT);
            let vaR = response.filter(ob => ob.concept_id === CONSTANT.VARIGHT);
            let piL = response.filter(ob => ob.concept_id === CONSTANT.PINEHOLELEFT);
            let piR = response.filter(ob => ob.concept_id === CONSTANT.PINEHOLERIGHT);
            let ioL = response.filter(ob => ob.concept_id === CONSTANT.EMRIOPLEFT);
            let ioR = response.filter(ob => ob.concept_id === CONSTANT.EMRIOPRIGHT);
            let blsug = response.filter(ob => ob.concept_id === CONSTANT.EMRBLOODSUGAR);
            let lacrDuctR = response.filter(ob => ob.concept_id === CONSTANT.EMRLACRIMALDUCTRIGHT);
            let lacrDuctL = response.filter(ob => ob.concept_id === CONSTANT.EMRLACRIMALDUCTLEFT);
            let vaMesR = response.filter(ob => ob.concept_id === CONSTANT.EMRVAMEASUREMENTRIGHT);
            let vaMesL = response.filter(ob => ob.concept_id === CONSTANT.EMRVAMEASUREMENTLEFT);
            let refe = response.filter(ob => ob.concept_id === CONSTANT.EMRREFERRAL);
            // let follw = response.filter(ob => ob.concept_id === CONSTANT.INFECTIONHISTORYRIGHTFOLLOWUP);
            if (vaL.length) {
                setAcuityLeftDignosis(vaL);
            }
            if (vaR.length) {
                setAcuityRightDignosis(vaR);
            }
            if (piL.length) {
                setPinholeLeftDignosis(piL);
            }
            if (piR.length) {
                setPinholeRightDignosis(piR);
            }
            if (ioL.length) {
                setIopLeftDiagnosis(ioL);
            }
            if (ioR.length) {
                setIopRightDiagnosis(ioR);
            }
            if (blsug.length) {
                setBloodSugarDiagnosis(blsug);
            }
            if (lacrDuctR.length) {
                setLacrimalDuctRightDiagnosis(lacrDuctR);
            }
            if (lacrDuctL.length) {
                setLacrimalDuctLeftDiagnosis(lacrDuctL);
            }
            if (vaMesR.length) {
                setVaMeasurementRightDiagnosis(vaMesR);
            }
            if (vaMesL.length) {
                setVaMeasurementLeftDiagnosis(vaMesL);
            }
            if (refe.length) {
                setReferralDiagnosis(refe);
            }
        } catch (e) {
            console.log('Set Diagnosis Exception: ', e);
        }
    }

    const editEMR = async (editData = []) => {
        try {
            editData.forEach(async data => {
                let { id, auditPayload, putPayload } = data;
                await AuditFunction.postAudit(auditPayload);
                Services.PutObsString(id, putPayload).then(response => {
                    response = {
                        ...auditPayload,
                        id,
                        obs_string: {
                            value: putPayload?.value
                        }
                    }
                    setDiagnosisValue([response]);
                })
            })
            onChange('fetchAudit');
            resetField();
        } catch (err) {
            console.log('Edit Exception :', err);
        }
    }

    const deleteEMR = (id, path) => {
        try {
            Services.DeleteObs(id).then(response => {
                if (response.status !== 400 && response.affected) {
                    if (path === 'acuityRightDignosis') {
                        let newData = [...acuityRightDignosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setAcuityRightDignosis(newData);
                        }
                    } else if (path === 'pinholeRightDignosis') {
                        let newData = [...pinholeRightDignosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setPinholeRightDignosis(newData);
                        }
                    } else if (path === 'iopRightDiagnosis') {
                        let newData = [...iopRightDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setIopRightDiagnosis(newData);
                        }
                    } else if (path === 'acuityLeftDignosis') {
                        let newData = [...acuityLeftDignosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setAcuityLeftDignosis(newData);
                        }
                    } else if (path === 'pinholeLeftDignosis') {
                        let newData = [...pinholeLeftDignosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setPinholeLeftDignosis(newData);
                        }
                    } else if (path === 'iopLeftDiagnosis') {
                        let newData = [...iopLeftDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setIopLeftDiagnosis(newData);
                        }
                    } else if (path === 'lacrimalDuctRightDiagnosis') {
                        let newData = [...lacrimalDuctRightDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setLacrimalDuctRightDiagnosis(newData);
                        }
                    } else if (path === 'lacrimalDuctLeftDiagnosis') {
                        let newData = [...lacrimalDuctLeftDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setLacrimalDuctLeftDiagnosis(newData);
                        }
                    } else if (path === 'bloodSugarDiagnosis') {
                        let newData = [...bloodSugarDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setBloodSugarDiagnosis(newData);
                        }
                    } else if (path === 'vaMeasurementRightDiagnosis') {
                        let newData = [...vaMeasurementRightDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setVaMeasurementRightDiagnosis(newData);
                        }
                    } else if (path === 'vaMeasurementLeftDiagnosis') {
                        let newData = [...vaMeasurementLeftDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setVaMeasurementLeftDiagnosis(newData);
                        }
                    } else if (path === 'referralDiagnosis') {
                        let newData = [...referralDiagnosis];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setReferralDiagnosis(newData);
                        }
                    }
                    // else if (path === 'followUpDiagnosis') {
                    //     let newData = [...followUpDiagnosis];
                    //     let index = newData.findIndex(dt => dt.id === id);
                    //     if (index !== -1) {
                    //         newData.splice(index, 1);
                    //         setFollowUpDiagnosis(newData);
                    //     }
                    // }
                    openNotificationWithIcon('success', 'EMR Deleted');
                } else {
                    openNotificationWithIcon('error', 'Server Error')
                }
            })
        } catch (e) {
            console.log('Delete EMR exception: ', e);
        }

    }

    const resetField = () => {
        setVisualRight('');
        setPinholeRight('');
        setVisualLeft('');
        setPinholeLeft('');
        setIopRight();
        setIopLeft();
        setBloodSugar('');
        setLacrimalDuctRight('');
        setLacrimalDuctLeft('');
        setVaMeasurementRight('');
        setVaMeasurementLeft('');
        setVaOtherLeft('');
        setVaOtherRight('');
        setReferral('');
        // setFollowUP('');
    }


    return (
        <div>
            <Card title={(<>EMR Data</>)} style={visitComplete && userInfo?.user?.person_type_id !== CONSTANT.REVIEWER ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                {EMRAudit ?
                    <>
                        <EMRWrapper>
                            <EMRSection>
                                <EMRInline>
                                    <div style={{ width: 200 }}></div>
                                    <Checkbox checked={rightAll} onChange={(e) => onChangeHandler('rightAll', e.target.checked)}><h4 style={{ margin: '0px' }}>n/a</h4></Checkbox>
                                </EMRInline>
                                <div style={{ marginTop: 20 }}>
                                    <EMRInline>
                                        <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.VARIGHT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.VARIGHT, 'Visual Acuity Right')} />
                                        <div><b>Visual Acuity Right</b></div>
                                    </EMRInline>
                                    <EMRInline>
                                        <AutoComplete options={visualAndPinoleData}
                                            style={{ width: 200 }}
                                            placeholder="Visual Acuity Right"
                                            value={visualRight} onChange={(e) => setVisualRight(e)}
                                            filterOption={(inputValue, option) =>
                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                        </AutoComplete>
                                        <Checkbox onChange={(e) => setVisualRight(e.target.checked ? 'n/a' : '')}></Checkbox>
                                    </EMRInline>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <EMRInline>
                                        <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.PINEHOLERIGHT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.PINEHOLERIGHT, 'Pinhole Acuity Right')} />
                                        <div><b>Pinhole Acuity Right</b></div>
                                    </EMRInline>
                                    <EMRInline>
                                        <AutoComplete options={visualAndPinoleData}
                                            style={{ width: 200 }}
                                            placeholder="Pinhole Acuity Right"
                                            value={pinholeRight} onChange={(e) => setPinholeRight(e)}
                                            filterOption={(inputValue, option) =>
                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                        </AutoComplete>
                                        <Checkbox onChange={(e) => setPinholeRight(e.target.checked ? 'n/a' : '')}></Checkbox>
                                    </EMRInline>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <EMRInline>
                                        <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRIOPRIGHT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRIOPRIGHT, 'IOP Right')} />
                                        <div><b>IOP Right</b></div>
                                    </EMRInline>
                                    <EMRInline>
                                        <Input placeholder="IOP Right" style={{ width: '200px' }} value={iopRight} onChange={(e) => setIopRight(e.target.value)} />
                                        <Checkbox onChange={(e) => setIopRight(e.target.checked ? 'n/a' : '')}></Checkbox>
                                    </EMRInline>
                                </div>
                                <div>
                                    {acuityRightDignosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'acuityRightDignosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                    {pinholeRightDignosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'pinholeRightDignosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                    {iopRightDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'iopRightDiagnosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                </div>
                            </EMRSection>
                            <EMRSection>
                                <EMRInline>
                                    <div style={{ width: 200 }}></div>
                                    <Checkbox checked={leftAll} onChange={(e) => onChangeHandler('leftAll', e.target.checked)}><h4 style={{ margin: '0px' }}>n/a</h4></Checkbox>
                                </EMRInline>
                                <div style={{ marginTop: 20 }}>
                                    <EMRInline>
                                        <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.VALEFT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.VALEFT, 'Visual Acuity Left')} />
                                        <div><b>Visual Acuity Left</b></div>
                                    </EMRInline>
                                    <EMRInline>
                                        <AutoComplete options={visualAndPinoleData}
                                            style={{ width: 200 }}
                                            placeholder="Visual Acuity Left"
                                            value={visualLeft} onChange={(e) => setVisualLeft(e)}
                                            filterOption={(inputValue, option) =>
                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                        </AutoComplete>
                                        <Checkbox onChange={(e) => setVisualLeft(e.target.checked ? 'n/a' : '')} ></Checkbox>

                                    </EMRInline>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <EMRInline>
                                        <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.PINEHOLELEFT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.PINEHOLELEFT, 'Pinhole Acuity Left')} />
                                        <div><b>Pinhole Acuity Left</b></div>
                                    </EMRInline>
                                    <EMRInline>
                                        <AutoComplete options={visualAndPinoleData}
                                            style={{ width: 200 }}
                                            placeholder="Pinhole Acuity Left"
                                            value={pinholeLeft} onChange={(e) => setPinholeLeft(e)}
                                            filterOption={(inputValue, option) =>
                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                                        </AutoComplete>
                                        <Checkbox onChange={(e) => setPinholeLeft(e.target.checked ? 'n/a' : '')}></Checkbox>
                                    </EMRInline>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <EMRInline>
                                        <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRIOPLEFT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRIOPLEFT, 'IOP Left')} />
                                        <div><b>IOP Left</b></div>
                                    </EMRInline>
                                    <EMRInline>
                                        <Input placeholder="IOP Left" style={{ width: '200px' }} value={iopLeft} onChange={(e) => setIopLeft(e.target.value)} />
                                        <Checkbox onChange={(e) => setIopLeft(e.target.checked ? 'n/a' : '')}></Checkbox>
                                    </EMRInline>
                                </div>
                                <div>
                                    {acuityLeftDignosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'acuityLeftDignosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                    {pinholeLeftDignosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'pinholeLeftDignosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                    {iopLeftDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'iopLeftDiagnosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                </div>
                            </EMRSection>
                        </EMRWrapper>

                        <Divider />
                        <h4 style={{ marginTop: 0 }}>VA Mesasurment Type:</h4>
                        <EMRWrapper ref={ref}>
                            <EMRSection>
                                <EMRInline>
                                    <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRVAMEASUREMENTRIGHT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRVAMEASUREMENTRIGHT, 'Visual Acuity Measurement Right')} />
                                    <div>Right Eye</div>
                                </EMRInline>
                                <Divider />
                                <div>
                                    <Radio.Group value={vaMeasurementRight} onChange={(e) => onChangeHandler('vaMeasurementRight', e.target.value)}>
                                        <Space direction="vertical">
                                            {vaMeasurementList?.map((lens, index) =>
                                                <div key={index}>
                                                    <Radio key={index} value={lens}>{lens}</Radio>
                                                    {lens === 'Other VA type' && vaMeasurementRight === 'Other VA type' ?
                                                        <Input placeholder="Enter text" value={vaOtherRight} onChange={e => setVaOtherRight(e.target.value)} style={{ width: 150, margin: 5 }} />
                                                        : null}
                                                </div>
                                            )}
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <div>
                                    {vaMeasurementRightDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'vaMeasurementRightDiagnosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                </div>
                            </EMRSection>
                            <EMRSection>
                                <EMRInline>
                                    <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRVAMEASUREMENTLEFT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRVAMEASUREMENTLEFT, 'Visual Acuity Measurement Left')} />
                                    <div>Left Eye</div>
                                </EMRInline>
                                <Divider />
                                <div>
                                    <Radio.Group value={vaMeasurementLeft} onChange={(e) => onChangeHandler('vaMeasurementLeft', e.target.value)}>
                                        <Space direction="vertical">
                                            {vaMeasurementList?.map((lens, index) =>
                                                <div key={index}>
                                                    <Radio key={index} value={lens}>{lens}</Radio>
                                                    {lens === 'Other VA type' && vaMeasurementLeft === 'Other VA type' ?
                                                        <Input placeholder="Enter text" value={vaOtherLeft} onChange={e => setVaOtherLeft(e.target.value)} style={{ width: 150, margin: 5 }} />
                                                        : null}
                                                </div>
                                            )}
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <div>
                                    {vaMeasurementLeftDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'vaMeasurementLeftDiagnosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                </div>
                            </EMRSection>
                        </EMRWrapper>

                        <Divider />
                        <h4 style={{ marginTop: 0 }}>Lacrimal Duct testing:</h4>
                        <EMRWrapper>
                            <EMRSection>
                                <EMRInline>
                                    <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRLACRIMALDUCTRIGHT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRLACRIMALDUCTRIGHT, 'Lacrimal Duct Right')} />
                                    <div>Right Eye</div>
                                </EMRInline>
                                <Divider />
                                <div>
                                    <Radio.Group value={lacrimalDuctRight} onChange={(e) => onChangeHandler('lacrimalDuctRight', e.target.value)}>
                                        <Space direction="vertical">
                                            {lacrimalDuctData?.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                        </Space>
                                    </Radio.Group>
                                </div>

                                <div>
                                    {lacrimalDuctRightDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'lacrimalDuctRightDiagnosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}
                                </div>
                            </EMRSection>
                            <EMRSection>
                                <EMRInline>
                                    <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRLACRIMALDUCTLEFT === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRLACRIMALDUCTLEFT, 'Lacrimal Duct Left')} />
                                    <div>Left Eye</div>
                                </EMRInline>
                                <Divider />
                                <div>
                                    <Radio.Group value={lacrimalDuctLeft} onChange={(e) => onChangeHandler('lacrimalDuctLeft', e.target.value)}>
                                        <Space direction="vertical">
                                            {lacrimalDuctData?.map((lens, index) => <Radio key={index} value={lens}>{lens}</Radio>)}
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <div>
                                    {lacrimalDuctLeftDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>{diagnosis.obs_string.value}</div>
                                                <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'lacrimalDuctLeftDiagnosis')} style={{ color: 'red' }} />
                                            </div>)
                                    } /></div>
                                    )}

                                </div>
                            </EMRSection>

                            {/* <EMRSection> */}
                            {/* <div style={{ display: 'flex', gap: '1rem', alignItems: 'baseline' }}>
                            <Input placeholder="Blood Sugar" value={bloodSugar} onChange={(e) => setBloodSugar(e.target.value)} />
                            <Checkbox onChange={(e) => setBloodSugar(e.target.checked ? 'n/a' : '')} style={{ marginTop: '26px' }}>n/a</Checkbox>
                        </div>
                        {bloodSugarDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'bloodSugarDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )} */}
                            {/* {showInfectionFragmentRight ? <div>
                            <b>Next scheduled follow-up visit</b>
                            <DatePicker size="medium" value={followUp !== "" ? dayjs(followUp) : null} style={{ width: 350, display: 'block' }} format="YYYY-MM-DD" onChange={(e, format) => setFollowUP(format)} />
                        </div> : null}
                        {followUpDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                            (
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>{diagnosis.obs_string.value}</div>
                                    <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'followUpDiagnosis')} style={{ color: 'red' }} />
                                </div>)
                        } /></div>
                        )} */}
                            {/* </EMRSection> */}
                        </EMRWrapper>
                        {Server.ENV.name === 'sceh' ?
                            <>
                                <Divider />
                                <EMRInline>
                                    <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRREFERRAL === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRREFERRAL, 'Referral Type')} />
                                    <h4>Referral Type</h4>
                                </EMRInline>
                                <div>
                                    <Radio.Group onChange={(e) => onChangeHandler('referral', e.target.value)}>
                                        <Space direction="vertical" >
                                            {referralList?.map(rL => <Radio value={rL} key={rL}>{rL}</Radio>)}
                                        </Space>
                                    </Radio.Group>
                                </div>
                                {referralDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                    (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'referralDiagnosis')} style={{ color: 'red' }} />
                                        </div>)
                                } /></div>
                                )}
                                <Divider />
                                <EMRInline>
                                    <QuestionCircleFilled style={{ color: getColor(EMRAudit?.filter(vr => CONSTANT.EMRBLOODSUGAR === vr.concept_id)?.at(-1)?.status) }} title="Show all queries and audit log" onClick={() => onClickHandler('audit', CONSTANT.EMRBLOODSUGAR, 'Random Blood Sugar')} />
                                    <h4>Random Blood Sugar</h4>
                                </EMRInline>
                                <div>
                                    <Input style={{ width: '300px' }} placeholder="Blood Sugar" value={bloodSugar} onChange={(e) => setBloodSugar(e.target.value)} /> mg/dL
                                    {/* <Checkbox onChange={(e) => setBloodSugar(e.target.checked ? 'n/a' : '')} style={{ marginTop: '26px' }}>n/a</Checkbox> */}
                                </div>
                                {bloodSugarDiagnosis?.map(diagnosis => <div key={diagnosis.id}><br /><Alert type="info" message={
                                    (
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div>{diagnosis.obs_string.value}</div>
                                            <DeleteFilled onClick={() => onClickHandler('deleteDiagnosis', diagnosis.id, 'bloodSugarDiagnosis')} style={{ color: 'red' }} />
                                        </div>)
                                } /></div>
                                )}
                            </> : null}
                        <br />
                        {/* <div style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={submitEMR}>Submit EMR</Button>
                </div> */}
                    </> : null}
            </Card>
            {contextHolder}
        </div >
    )
}