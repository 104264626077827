import styled from 'styled-components';

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ImageSection = styled.div`
    width: 48%
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
`;

export const PdfWrapper = styled.div`

`;

export const PreviewCard = styled.a`
    cursor: pointer;
    height: 50px;
    align-content: center;
    text-decoration: underline;
    color: blue;
    display: flex;
    align-items: center;
    gap: 1rem;
`;